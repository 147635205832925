export default {
    name: 'popover',
    data(){
        return {  
            content_type: this.meeting_popup_content,
            grp_name : this.group_name,
            view_name: '',
            updated_data:''
        }    
    },
    props: {  
        popup_type : String, 
        action_type: String,
        meeting_popup_content: String,
        meeting_title: String,
        group_name : String,
        group_id : String,
        pop:String,

    },
    async mounted(){
        if(this.$refs.description){
            this.$refs.description.default_value = this.$refs.description.textContent;
        }
        if(this.$refs.focus_btn){
            this.$refs.focus_btn.focus();
        }
        document.addEventListener('keydown', this.keyactive);
    },

    methods:{
        update_data(e){
            this.updated_data = e.srcElement.innerHTML   
        },
        save_data(){
            this.content_type = this.updated_data
        },
        keyactive(e){
            if(e.which == this.Constants.LEFT_ARROW){
                this.$refs.focus_cancel_btn.focus()
            }
            else if(e.which == this.Constants.RIGHT_ARROW){
                this.$refs.focus_btn.focus()
            }
        },
        make_textdecoration(event){
            switch(event.srcKey){
                case 'bold':
                    document.execCommand('bold');
                    break;
                case 'italic':
                    document.execCommand('italic');
                    break
                case 'underline':
                    document.execCommand('underline');
                    break;
                case 'bullets':
                    document.execCommand('insertUnorderedList',true,'');
                    break; 
             }
        },
        closePopOver(){
            this.$emit('closePopUp');
        },

        confirmPopOver(type,content=''){
            this.$emit('confirmPopUp',type,content);
        },

        manageGroup(type){
            switch (type){
                case 'save':
                    if(this.group_id == '-1' && this.grp_name != ''){
                        this.$emit('group_update',['new',this.grp_name])
                    }else if(this.grp_name == ''){
                        this.$refs.group_input.focus();
                    }else if(this.group_id != '-1'){
                        this.$emit('group_update',['edit',this.grp_name])
                    }
                    break;
                case 'delete':
                    this.$emit('group_update',['delete',this.grp_name])
                    break;
            }
        },
    },
      directives:{
        content_format:{ 
            inserted(el){  
               el.value =  el.value.replace(/<(style|script|iframe)[^>]*?>[\s\S]+?<\/\1\s*>/gi,'').replace(/<[^>]+?>/g,'').replace(/\s+/g,' ').replace(/ /g,' ').replace(/>/g,' ').replace(/&nbsp;/,' ');
            }
        }
    },
}